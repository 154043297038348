
import { computed, defineComponent, ref } from '@vue/composition-api';
// @ts-ignore
import Treeselect from '@riophae/vue-treeselect';
import { stateRefs, dataObjectFilter } from '@/store';
import DownloadDialog from '@/components/DownloadDialog.vue';
import useBulkDownload from '@/use/useBulkDownload';
import { humanFileSize } from '@/data/utils';
import { api } from '@/data/api';

export default defineComponent({

  components: {
    DownloadDialog,
    Treeselect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    searchResultCount: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const termsDialog = ref(false);

    const {
      loading,
      downloadSummary,
      downloadOptions,
      download,
    } = useBulkDownload(stateRefs.conditions, dataObjectFilter);

    function createLabelString(label: string, count: number, size: number): string {
      const labelString = `${label} (${count}`;
      if (size > 0) {
        return `${labelString}, ${humanFileSize(size)})`;
      }
      return `${labelString})`;
    }

    const options = computed(() => Object.entries(downloadOptions.value)
      .map(([key, val]) => ({
        id: key,
        label: createLabelString(key, val.count, val.size),
        children: Object.entries(val.file_types).map(([filetype, fileTypeStats]) => ({
          id: `${key}::${filetype}`,
          label: createLabelString(filetype, fileTypeStats.count, fileTypeStats.size),
        })),
      })));

    async function createAndDownload() {
      const val = await download();
      termsDialog.value = false;
      window.location.assign(val.url);
    }

    function handleLoginClick() {
      api.initiateOrcidLogin();
    }

    return {
      bulkDownloadSelected: stateRefs.bulkDownloadSelected,
      options,
      loading,
      downloadSummary,
      termsDialog,
      createAndDownload,
      humanFileSize,
      handleLoginClick,
    };
  },
});
