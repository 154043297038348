
import { defineComponent } from '@vue/composition-api';
import { api } from '@/data/api';

export default defineComponent({
  setup() {
    function handleLoginClick() {
      api.initiateOrcidLogin('submission');
    }
    return {
      handleLoginClick,
    };
  },
});
