
import {
  computed, defineComponent, PropType, reactive,
} from '@vue/composition-api';
import { flattenDeep } from 'lodash';

import { DataTableHeader } from 'vuetify';
import { humanFileSize } from '@/data/utils';
import { client, BiosampleSearchResult, OmicsProcessingResult } from '@/data/api';
import { stateRefs, acceptTerms } from '@/store';

import DownloadDialog from './DownloadDialog.vue';

const descriptionMap: Record<string, string> = {
  'fastq.gz': 'Raw output file',
  'filterStats.txt': 'Reads QC summary statistics',
  'filtered.fastq.gz': 'Reads QC result fastq (clean data)',
  'mapping_stats.txt': 'Assembled contigs coverage information',
  'assembly_contigs.fna': 'Final assembly contigs fasta',
  'assembly_scaffolds.fna': 'Final assembly scaffolds fasta',
  'assembly.agp': 'An AGP format file describes the assembly',
  'pairedMapped_sorted.bam': 'Sorted bam file of reads mapping back to the final assembly',
  'KO TSV': 'Tab delimited file for KO annotation.',
  'EC TSV': 'Tab delimited file for EC annotation.',
  'Protein FAA': 'FASTA amino acid file for annotated proteins.',
};

export default defineComponent({
  components: { DownloadDialog },

  props: {
    omicsProcessing: {
      type: Array as PropType<OmicsProcessingResult[]>,
      required: true,
    },
    omicsType: {
      type: String,
      required: true,
    },
    loggedInUser: {
      type: Boolean,
      default: false,
    },
    biosample: {
      type: Object as PropType<BiosampleSearchResult>,
      required: true,
    },
  },

  setup(props) {
    const headers: DataTableHeader[] = [
      {
        text: '',
        value: 'group_name',
        sortable: false,
      },
      {
        text: 'Data Object Type',
        value: 'object_type',
        sortable: false,
      },
      {
        text: 'Data Object Description',
        value: 'object_description',
        sortable: false,
      },
      {
        text: 'File Size',
        value: 'file_size_bytes',
        width: 100,
        sortable: false,
      },
      {
        text: 'Downloads',
        value: 'downloads',
        width: 80,
        sortable: false,
      },
      {
        text: 'Download',
        value: 'action',
        width: 80,
        sortable: false,
      },
    ];

    const termsDialog = reactive({
      item: null as null | OmicsProcessingResult,
      value: false,
    });

    function getOmicsDataWithInputIds(omicsProcessing: OmicsProcessingResult) {
      const biosampleInputIds = (omicsProcessing.biosample_inputs as BiosampleSearchResult[]).map((input) => input.id);
      return omicsProcessing.omics_data.map((omics) => {
        const omicsCopy = { ...omics };
        omicsCopy.inputIds = biosampleInputIds;
        return omicsCopy;
      });
    }

    const items = computed(() => flattenDeep(
      flattenDeep(props.omicsProcessing.map((p) => (getOmicsDataWithInputIds(p))))
        .map((omics_data) => omics_data.outputs
          .filter((data) => data.file_type && data.file_type_description)
          .map((data_object, i) => ({
            ...data_object,
            omics_data,
            /* TODO Hack to replace metagenome with omics type name */
            group_name: omics_data.name.replace('Metagenome', props.omicsType),
            newgroup: i === 0,
          }))),
    ));

    function getRelatedBiosampleIds(omicsData: any) {
      if (!omicsData || !omicsData.inputIds) {
        return [];
      }
      return omicsData.inputIds.filter((id: string) => id !== props.biosample.id);
    }

    async function getDownloadUrlAndOpen(item: OmicsProcessingResult) {
      if (typeof item.url === 'string') {
        const { data } = await client.get(item.url, { baseURL: '' });
        window.open(data.url, '_blank', 'noopener,noreferrer');
      }
    }

    async function handleDownload(item: OmicsProcessingResult) {
      if (typeof item.url === 'string') {
        if (stateRefs.hasAcceptedTerms.value) {
          getDownloadUrlAndOpen(item);
        } else {
          termsDialog.item = item;
          termsDialog.value = true;
        }
      }
    }

    function onAcceptTerms() {
      termsDialog.value = false;
      acceptTerms();
      getDownloadUrlAndOpen(termsDialog.item!);
      termsDialog.item = null;
    }

    return {
      onAcceptTerms,
      handleDownload,
      descriptionMap,
      headers,
      items,
      humanFileSize,
      termsDialog,
      getRelatedBiosampleIds,
    };
  },
});
