
import { computed, defineComponent, PropType } from '@vue/composition-api';

import SubmissionStepper from './Components/SubmissionStepper.vue';
import OrcidId from '@/components/Presentation/OrcidId.vue';

import { stateRefs } from '@/store';
import { getSubmissionLockedBy } from './store';
import { unlockSubmission } from './store/api';

export default defineComponent({
  components: { SubmissionStepper, OrcidId },

  props: {
    id: {
      type: String as PropType<string | null>,
      default: null,
    },
  },

  setup(props) {
    const loggedInUserHasLock = computed(() => {
      const lockedByUser = getSubmissionLockedBy();
      if (!lockedByUser) {
        return true;
      }
      if (lockedByUser.orcid === stateRefs.user.value?.orcid) {
        return true;
      }
      return false;
    });

    const isEditingSubmission = computed(() => props.id !== null);

    window.addEventListener('beforeunload', () => {
      if (isEditingSubmission.value) {
        if (props.id) {
          unlockSubmission(props.id);
        }
      }
    });

    return { loggedInUserHasLock, getSubmissionLockedBy, isEditingSubmission };
  },

});
