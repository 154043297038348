
import {
  computed,
  defineComponent,
  PropType,
  toRef,
  watch,
} from '@vue/composition-api';
import { stateRefs } from '@/store';
import useRequest from '@/use/useRequest';
import { loadRecord } from './store';
import AppBanner from '@/components/AppBanner.vue';
import TitleBanner from '@/views/SubmissionPortal/Components/TitleBanner.vue';
import IntroBlurb from '@/views/SubmissionPortal/Components/IntroBlurb.vue';
import IconBar from '@/views/SubmissionPortal/Components/IconBar.vue';
import LoginPrompt from '@/views/SubmissionPortal/Components/LoginPrompt.vue';

export default defineComponent({
  components: {
    AppBanner,

    IconBar,
    IntroBlurb,
    LoginPrompt,
    TitleBanner,
  },
  props: {
    id: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup(props, { root }) {
    const req = useRequest();

    function load() {
      const { id } = props;
      if (id) req.request(() => loadRecord(id));
      else req.reset();
    }

    watch(toRef(props, 'id'), load);
    load();

    const showBanner = computed(() => root.$route.path === '/submission/home');

    return {
      stateRefs,
      req,
      showBanner,
    };
  },
});
