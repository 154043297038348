var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[(_vm.termsDialog.item)?_c('v-dialog',{attrs:{"width":400},model:{value:(_vm.termsDialog.value),callback:function ($$v) {_vm.$set(_vm.termsDialog, "value", $$v)},expression:"termsDialog.value"}},[_c('DownloadDialog',{on:{"clicked":_vm.onAcceptTerms}})],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [((item.newgroup || index == 0) && item.group_name)?_c('tr',{style:({ 'background-color': '#e0e0e0' })},[_c('td',{attrs:{"colspan":"6"}},[_c('b',[_vm._v("Workflow Activity:")]),_vm._v(" "+_vm._s(item.group_name)+" "),_c('br'),(_vm.getRelatedBiosampleIds(item.omics_data).length)?_c('div',[_c('v-icon',[_vm._v(" mdi-flask-outline ")]),_c('span',{staticClass:"text-subtitle-2 grey--text text--darken-3"},[_c('b',[_vm._v("Associated biosample inputs:")])]),_vm._l((_vm.getRelatedBiosampleIds(item.omics_data)),function(biosampleId){return _c('router-link',{key:biosampleId,staticClass:"ml-2 grey--text text--darken-3",attrs:{"to":{name: 'Sample', params: { id: biosampleId }}}},[_vm._v(" "+_vm._s(biosampleId)+" ")])})],2):_vm._e()])]):_vm._e(),_c('tr',[_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({style:({ visibility: item.selected ? 'visible' : 'hidden'})},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("This file is included in the currently selected bulk download")])])],1),_c('td',[_vm._v(_vm._s(item.file_type))]),_c('td',[_vm._v(_vm._s(item.file_type_description))]),_c('td',[_vm._v(_vm._s(_vm.humanFileSize(item.file_size_bytes)))]),_c('td',[_vm._v(_vm._s(item.downloads))]),_c('td',[_c('v-tooltip',{attrs:{"disabled":!!(_vm.loggedInUser && item.url),"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[(item.url)?_c('v-btn',_vm._b({attrs:{"icon":"","disabled":!_vm.loggedInUser,"color":"primary"},on:{"click":function($event){return _vm.handleDownload(item)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-download")])],1):_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v(" mdi-file-hidden ")])],1)],1)]}}],null,true)},[(item.url)?_c('span',[_vm._v(" You must be logged in ")]):_c('span',[_vm._v(" File unavailable ")])])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }