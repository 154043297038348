
import { defineComponent } from '@vue/composition-api';
import AuthButton from '@/components/Presentation/AuthButton.vue';
import Menus from '@/menus';

export const APP_HEADER_HEIGHT = 82;

export default defineComponent({
  components: {
    AuthButton,
  },
  setup() {
    return {
      Menus,
      APP_HEADER_HEIGHT,
    };
  },
});
