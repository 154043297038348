
// @ts-ignore
import NmdcSchema from 'nmdc-schema/nmdc_schema/nmdc_materialized_patterns.yaml';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
} from '@vue/composition-api';
import Definitions from '@/definitions';
import {
  studyForm,
  studyFormValid,
  permissionTitleToDbValueMap,
  permissionTitle,
  isOwner,
  canEditSubmissionMetadata,
} from '../store';
import { stateRefs } from '@/store';
import SubmissionDocsLink from './SubmissionDocsLink.vue';
import SubmissionPermissionBanner from './SubmissionPermissionBanner.vue';

export default defineComponent({
  components: { SubmissionDocsLink, SubmissionPermissionBanner },
  setup() {
    const formRef = ref();

    const currentUserOrcid = computed(() => stateRefs.user.value?.orcid);

    const permissionHelpText = ref([
      {
        title: 'Viewer',
        description: 'Viewers can see all components of a submission, but cannot edit.',
      },
      {
        title: 'Metadata Contributor',
        description: 'Metadata contributors can view all components of a submission and can only edit the sample metadata information on the last step of the submission process.',
      },
      {
        title: 'Editor',
        description: 'Editors of a submission have full permission to edit every aspect of the submission with the exception of permission levels.',
      },
      {
        title: 'Owner',
        description: 'This level of permission is automatically assigned to the submission author and Principal Investigator. These users can edit every aspect of the submission.',
      },
    ]);

    function addContributor() {
      studyForm.contributors.push({
        name: '',
        orcid: '',
        roles: [],
        permissionLevel: null,
      });
    }

    function addFundingSource() {
      if (studyForm.fundingSources === null || studyForm.fundingSources.length === 0) {
        studyForm.fundingSources = [''];
      } else {
        studyForm.fundingSources.push('');
      }
    }

    function requiredRules(msg: string, otherRules: ((v: string) => unknown)[] = []) {
      return [
        (v: string) => !!v || msg,
        ...otherRules,
      ];
    }

    const orcidRequiredRule = (idx: number) => (v: string) => {
      if (idx > studyForm.contributors.length) return true;
      const contributor = studyForm.contributors[idx];
      // show error when: permission level exists, but orcid does not
      return (contributor.permissionLevel && !!v) || !contributor.permissionLevel || 'ORCID iD is required if a permission level is specified';
    };

    const uniqueOrcidRule = (idx: number) => (v: string) => {
      if (idx > studyForm.contributors.length || !v) return true;
      const existingOrcids = new Set(studyForm.contributors.filter((contributor, contributorListIndex) => idx !== contributorListIndex).map((contributor) => contributor.orcid));
      return !existingOrcids.has(v) || 'ORCID iDs must be unique';
    };

    const permissionLevelChoices: Ref<{ title: string, value: string }[]> = ref([]);
    Object.keys(permissionTitleToDbValueMap).forEach((title) => {
      permissionLevelChoices.value.push({
        title,
        value: permissionTitleToDbValueMap[title as permissionTitle],
      });
    });

    onMounted(async () => {
      formRef.value.validate();
    });

    return {
      formRef,
      studyForm,
      studyFormValid,
      NmdcSchema,
      Definitions,
      addContributor,
      addFundingSource,
      requiredRules,
      permissionLevelChoices,
      isOwner,
      canEditSubmissionMetadata,
      orcidRequiredRule,
      uniqueOrcidRule,
      currentUserOrcid,
      permissionHelpText,
    };
  },
});
