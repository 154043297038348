
import { defineComponent, ref } from '@vue/composition-api';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import AppBanner from '@/components/AppBanner.vue';
import OrcidId from '@/components/Presentation/OrcidId.vue';
import { stateRefs } from '@/store';
import { getRefreshToken } from '@/store/localStorage';

export default defineComponent({
  name: 'UserDetailPage',
  components: { OrcidId, AppBanner },

  setup() {
    const refreshTokenInput = ref();

    const refreshToken = getRefreshToken();
    let refreshTokenExpirationDate;
    if (refreshToken != null) {
      const decodedToken = jwtDecode(refreshToken);
      if (decodedToken.exp != null) {
        refreshTokenExpirationDate = moment.unix(decodedToken.exp).format('YYYY-MM-DD HH:mm:ss');
      }
    }
    const isTokenVisible = ref(false);
    const isCopyRefreshTokenSnackbarVisible = ref(false);

    const handleRefreshTokenCopyButtonClick = async () => {
      if (refreshToken != null) {
        await navigator.clipboard.writeText(refreshToken);
        isCopyRefreshTokenSnackbarVisible.value = true;
      }
    };

    const handleRefreshTokenVisibilityButtonClick = async () => {
      isTokenVisible.value = !isTokenVisible.value;
      if (isTokenVisible.value) {
        // Wait for the type of the input to change before selecting the text.
        // For some reason, nextTick isn't enough in this case.
        setTimeout(() => {
          refreshTokenInput.value.$refs.input.select();
        }, 50);
      }
    };

    return {
      user: stateRefs.user,
      userLoading: stateRefs.userLoading,
      origin: window.location.origin,
      refreshToken,
      refreshTokenExpirationDate,
      refreshTokenInput,
      isCopyRefreshTokenSnackbarVisible,
      isTokenVisible,
      handleRefreshTokenVisibilityButtonClick,
      handleRefreshTokenCopyButtonClick,
    };
  },
});
