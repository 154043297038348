
import { defineComponent } from '@vue/composition-api';
import { init, stateRefs } from '@/store';
import { api } from '@/data/api';
import { useRouter } from '@/use/useRouter';

export default defineComponent({
  props: {
    nav: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();

    function handleLogin() {
      if (!router) {
        api.initiateOrcidLogin();
        return;
      }

      const submissionState = 'submission';
      const submissionRegex = new RegExp(submissionState);
      let state = '';
      if (submissionRegex.test(router.currentRoute.path)) {
        state += submissionState;
      }
      api.initiateOrcidLogin(state);
    }

    async function handleLogout() {
      try {
        await api.logout();
      } catch (e) {
        // This can happen if the user attempts to log out after their access token has expired
        // and that's okay to silently ignore
      } finally {
        stateRefs.user.value = null;
        if (router) {
          await init(router, false);
        }
      }
    }

    return {
      handleLogin,
      handleLogout,
      me: stateRefs.user,
      loading: stateRefs.userLoading,
    };
  },
});
