var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"mx-3",attrs:{"value":_vm.filterText,"solo":"","label":"search","clearable":"","dense":"","hide-details":"","outlined":"","flat":"","append-icon":"mdi-magnify"},on:{"input":function($event){return _vm.$emit('update:filterText', $event || '')}}}),_c('v-list',{ref:"list",staticClass:"compact",attrs:{"dense":"","shaped":""}},[_vm._l((_vm.groupedFields),function(ref){
var groupname = ref[0];
var filteredFields = ref[1];
return _c('div',{key:groupname},[_c('v-subheader',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupedFields.length > 1 && filteredFields.length > 0),expression:"groupedFields.length > 1 && filteredFields.length > 0"}]},[_vm._v(" "+_vm._s(groupname !== 'undefined' ? groupname : 'Other')+" "),(groupname === 'GOLD Ecosystems')?_c('v-tooltip',{attrs:{"right":"","open-delay":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.goldDescription))])]):_vm._e()],1),_vm._l((filteredFields),function(sf){return [_c('v-menu',{key:sf.key,attrs:{"value":_vm.menuState[sf.key],"offset-x":"","close-on-content-click":false},on:{"input":function($event){return _vm.toggleMenu(sf.key, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.hasActiveConditions(sf.key)),expression:"!hasActiveConditions(sf.key)"}]},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.fieldDisplayName(sf.field, sf.table))+" ")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-play ")])],1)],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"500"}},[_vm._t("menu",null,null,{
                field: sf.field,
                table: sf.table,
                isOpen: _vm.menuState[sf.key],
                toggleMenu: function (val) { return _vm.toggleMenu(sf.key, val); },
              })],2)],1)]})],2)}),(_vm.facetValues.length && _vm.groupedFields.length)?_c('v-divider',{staticClass:"my-2"}):_vm._e(),(_vm.facetValues.length)?_c('v-subheader',[_vm._v(" Query Options ")]):_vm._e(),_vm._l((_vm.facetValues),function(condition){return _c('v-list-item',{key:((condition.table) + ":" + (condition.field) + ":" + (condition.value)),on:{"click":function($event){return _vm.$emit('select', condition)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(condition.value)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.fieldDisplayName(condition.field))+" "),(condition.op === 'like')?_c('span',[_vm._v("("+_vm._s(_vm.tableName(condition.table))+")")]):_vm._e()])],1),_c('v-list-item-icon',{staticClass:"align-self-center"},[_c('v-icon',[_vm._v(" mdi-filter-plus ")])],1)],1)}),(_vm.facetValues.length === 0 && _vm.groupedFields.length === 0)?_c('v-list-item',[_vm._v(" No search results ")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }